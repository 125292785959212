<template>
   <SettingsPage
      v-if="canEdit"
      type="business"
   >
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <div class="field">
      <label class="label">Balance</label>
      <div class="control">
         {{ USD(business.balance).format() }} <br/>

          <span
            v-if="success_msg"
            class="tag is-success">
            Purchase successful. Your balance is updated
            <button
              @click="success_msg = false"
              class="delete is-small"></button>
          </span>

      </div>
    </div>

    <form @submit.prevent="onSubmit">

      <div
        v-if="business.stripe_card_info != null"
        class="field has-addons"
      >
        <div class="control">
          <div class="select is-fullwidth">
            <select
              v-model="business.refill_amount"
              @change="detectChange()"
            >
              <option
                v-for="opt in prefund_options"
                :key="opt.value"
                :value="opt.value"
                :selected="business.refill_amount === opt.value"
                >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="control">
          <button
            @click="purchase()"
            :disabled="purchasing"
            :class="showPurchaseClass()"
          >
            Purchase credits now
          </button>
        </div>
      </div>

      <div class="field">
        <label class="label">Refill when balance is below</label>
        <div class="control">
          <div class="select">
            <select
              v-model="business.refill_trigger"
              @change="detectChange()"
            >
              <option
                v-for="opt in refill_options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label || 'No label' }}
              </option>
            </select>
          </div>
        </div>
      </div>


      <p
        v-if="business.refill_trigger == 'none'"
        class="has-text-danger"
        style="padding:10px"
      >
        Warning. When balance drops below deal award value, then deal will be deactivated. Autorefill recommended.
      </p>

      <label class="label">Payment method</label>
      <div
        v-if="business.stripe_card_info == null"
        class="card-holder"
      >
      <Card
        :class="complete"
        :stripe="stripe_token"
        @change="complete = $event.complete; detectChange()"
      />
      </div>
      <span
        v-else
        class="tag is-medium"
      >
        {{ business.stripe_card_info }}
        <button
          @click.prevent="deleteCard()"
          class="delete is-small">
        </button>
      </span>

      <div style="margin-top:20px">
        <BaseSubmit
          label="Save"
          :disabled="noChanges"
          :submitting="submitting"
          :clearShow="true"
        />
      </div>
    </form>
  </SettingsPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import SettingsPage from "@/components/SettingsPage.vue";
import Notification from "@/components/Notification.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import { Card, createToken } from "vue-stripe-elements-plus";

export default {
  components: {
    Card,
    SettingsPage,
    Notification,
    BaseSubmit
  },
   data() {
    return {
      loading: false,
      complete: false,
      noChanges: true,
      success_msg: false,
      error: null,
      submitting: false,
      purchasing: false,
      business: {
        refill_amount: 2500,
        refill_trigger: 1000
      },
      prefund_options: [
        {
          value: 2500,
          label:  "$25"
        },
        {
          value: 5000,
          label:  "$50"
        },
        {
          value: 10000,
          label:  "$100"
        },
        {
          value: 25000,
          label: "$250"
        },
        {
          value: 50000,
          label: "$500"
        },
        {
          value: 100000,
          label:  "$1,000"
        },
      ],
      refill_options: [
        {
          value: "1000",
          label:  "$10"
        },
        {
          value: "3000",
          label:  "$30"
        },
        {
          value: "10000",
          label:  "$100"
        },
        {
          value: "none",
          label:  "Do not auto refill"
        },
      ],
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    canEdit: function() {
      return this.$store.getters.canEdit;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    stripe_token: function() {
      let url = window.location.hostname;
       if (url == "www.localloot.app" || url == "localloot.app" || url == "deals.localloot.app") {
         return "pk_live_fW6GVHS1Kw3hbXMSRk4fmsEm00DvJ52YZa"
       } else {
        return "pk_test_zG5qg6LIZ7OKPI2xFOzN3Vgt00WzF7WjMy"
       }
    },
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {
      this.business = this.currentBusiness
    } else {
      this.$router.push("/o/organization-new")
    }
  },
  methods: {
    detectChange() {
      this.noChanges = false
    },
    showPurchaseClass() {
     if (this.purchasing) {
        return "button is-primary is-loading"
      } else {
        return "button is-primary"
      }
    },
    reset() {
      this.noChanges = true
      this.$router.go()
    },
    uploadImg(image_url) {
      this.business.image = image_url
    },
    purchase() {
      this.purchasing = true;

      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          purchase_amount: this.business.refill_amount,
        }
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.success_msg = true;
          this.business = this.currentBusiness
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
        .finally(() => {
          this.purchasing = false
        })
    },
    deleteCard() {
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
      }
      this.$store.dispatch("removeCreditCard", params)
        .then(() => {
          this.business.stripe_card_info = null
          this.complete = false
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    },
    onSubmit() {
      this.submitting = true;

      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: {
          refill_amount: this.business.refill_amount,
          refill_trigger: this.business.refill_trigger
        }
      }

      if (this.complete == true) {
        createToken()
          .then((result) => {
            params["organization"]["stripe_token_id"] = result.token.id
            params["organization"]["purchase_amount"] = 0 // we send $0 amount just to update the card
            this.sendUpdate(params)
          })
          .catch(error => {
            this.error = error
          })
      } else {
        this.sendUpdate(params)
      }

    },
    sendUpdate(params) {
       this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.business = this.currentBusiness
          this.submitting = false
          this.reset()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>

<style scoped>
.card-holder {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 10px 0;
}
</style>
